import './App.css';
import Routes from './Routes';
import './css/plugins.css';
import './css/style.css';
import './css/templete.css';
import './css/skin/skin-1.css'; 

function App() {
  return (
    <div className="App">
      <Routes />
    </div>
  );
}

export default App;
