import React from "react";

import { Link } from "react-router-dom";

const Header = () => {
  return (
    <header className="site-header header center mo-left header-style-2">
      <div className="sticky-header main-bar-wraper navbar-expand-lg">
        <div className="main-bar clearfix ">
          <div className="container clearfix">
            <div className="logo-header mostion">
              <Link to={"/"} className="dez-page">
                <img src={require("../images/logo.png")} alt="" />
              </Link>
            </div>
            <div
              className="header-nav navbar-collapse navbar myNavbar collapse justify-content-between"
              id="navbarNavDropdown"
            >
              <div className="logo-header mostion">
                <Link
                  to={"/"}
                  className="dez-page"
                  style={{
                    height: "20px",
                    width: "20px",
                  }}
                >
                  <img src={require("../images/logo.png")} alt="" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
