import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/header";
import { Carousel } from "react-bootstrap";

var img1 = require("../images/banner/bnr1.jpg");

function Product() {
  const [index, setIndex] = React.useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <>
      <Header />
      <div className="page-content bg-white">
        <div
          className="dlab-bnr-inr overlay-black-middle"
          style={{ backgroundImage: "url(" + img1 + ")" }}
        >
          <div className="container">
            <div className="dlab-bnr-inr-entry">
              <h1 className="text-white">Product Details</h1>
            </div>
          </div>
        </div>

        <div className="content-block">
          <div className="section-full content-inner-1 bg-gray-light">
            <div className="container woo-entry">
              <div className="row">
                <div className="col-lg-6 m-b30">
                  <div
                    className="product-gallery on-show-slider lightgallery"
                    id="lightgallery"
                  >
                    <div className="dlab-box">
                      <div className="dlab-thum-bx">
                        <Carousel
                          fade
                          activeIndex={index}
                          onSelect={handleSelect}
                          prevLabel=""
                          nextLabel=""
                        >
                          <Carousel.Item>
                            <img
                              src={require("../images/product/item2.jpg")}
                              alt=""
                            />
                          </Carousel.Item>
                          <Carousel.Item>
                            <img
                              src={require("../images/product/item3.JPG")}
                              alt=""
                            />
                          </Carousel.Item>
                        </Carousel>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 m-b30">
                  <div className="cart sticky-top">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                      }}
                    >
                      <div className="dlab-post-title">
                        <h4 className="post-title">Velvet Cake</h4>
                      </div>
                      <div className="relative">
                        <h3 className="m-tb10">₹2,130.00 </h3>
                        <div className="shop-item-rating">
                          <span className="rating-bx">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                          </span>
                          <span>4.9 Rating</span>
                        </div>
                      </div>
                      <div
                        className="shop-item-tage m-tb10"
                        style={{
                          display: "flex",
                        }}
                      >
                        <div>
                          <img
                            src="https://dukaan.b-cdn.net/original/dukaan-media/plugins/trusted_badges_v2/secure-checkout.svg"
                            alt="badge"
                          />
                        </div>
                        <div>
                          <img
                            src="https://dukaan.b-cdn.net/original/dukaan-media/plugins/trusted_badges_v2/satisfaction-guaranteed.svg"
                            alt="badge"
                          />
                        </div>
                        <div>
                          <img
                            src="https://dukaan.b-cdn.net/original/dukaan-media/plugins/trusted_badges_v2/premium-quality.svg"
                            alt="badge"
                          />
                        </div>
                      </div>
                      <div className="dlab-divider bg-gray tb15">
                        <i className="icon-dot c-square"></i>
                      </div>

                      <div
                        className="m-b10"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <span>
                          {" "}
                          - Luxurious, velvety, handcrafted chocolate.
                        </span>
                        <span> - Intense flavor, melts in mouth.</span>
                        <span>
                          {" "}
                          - Gluten-free, sugar-free, keto, vegetarian.
                        </span>
                      </div>
                    </div>
                    <Link to="https://rzp.io/l/yQvVWcTp">
                      <button
                        className="btn btnhover"
                        style={{
                          marginTop: "20px",
                        }}
                      >
                        <i className="ti-control-forward"></i> Buy now
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="dlab-tabs product-description tabs-site-button m-t30 m-b30">
                    <ul className="nav nav-tabs">
                      <li>
                        <Link to="#" className={"active"}>
                          Description
                        </Link>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div className="tab-pane active">
                        <div id="comments">
                          <div className="dlab-post-title">
                            <h4 className="post-title">Package Includes:</h4>
                          </div>
                          <div style={{ padding: "15px" }}>
                            <ul>
                              <li>
                                A luxurious, velvety, incredibly smooth and
                                creamy chocolate.
                              </li>
                              <li>
                                An intense chocolate flavor, handcrafted with
                                the finest ingredients in small batches to
                                ensure quality, freshness and flavor
                                consistency.
                              </li>
                              <li>
                                A truly indulgent and melt-in-your-mouth
                                experience.
                              </li>
                              <li>
                                It's a treat that brings pure pleasure with
                                every bite.
                              </li>
                              <li>
                                The attention to detail and commitment to
                                quality shine through in this experiential
                                chocolate.
                              </li>
                              <li>
                                It's a delight for chocolate enthusiasts seeking
                                a truly exceptional indulgence.
                              </li>
                              <li>
                                Elegant and sophisticated packaging make it an
                                ideal gift for chocolate lovers or a special
                                treat for yourself.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Product;
